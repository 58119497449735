.fern-modal {
  background: white;
  z-index: 2000;
  padding: 15px 15px 30px 15px;
  margin: 0 15px;
  width: 100%;
  max-width: 610px;
  position: relative;
  @screen tablet {
    padding: 45px 60px 30px 60px;
  }

  &.fullscreen {
    height: 100%;
    width: 100vw;
    max-width: 100vw;
    margin: 0;
    overflow-y: scroll;

    @screen small-desktop {
      height: auto;
      width: 100%;
      max-width: 610px;
      max-height: 95vh;
      margin: 0 15px;
    }
  }
}

.fullscreen-close-container {
  position: sticky;
  top: 0;
  text-align: right;
  margin-bottom: 15px;

  button {
    @apply bg-white;
    border-radius: 100%;
    height: 32px;
    width: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 0;
  }

  @screen small-desktop {
    margin-top: -30px;
    top: -30px;
    margin-right: -45px;
    margin-left: -45px;
    margin-bottom: 5px;

    button {
      height: auto;
      width: auto;
    }
  }
}

.fern-modal--close {
  position: absolute;
  top: 15px;
  right: 15px;
  border: none;
}

.fern-modal--button-container {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}

.fern-modal--buttons {
  display: grid;
  grid-column-gap: 15px;
  grid-row-gap: 15px;
  grid-template-columns: 1fr;

  button {
    min-width: 140px;
  }

  &.one-button {
    display: block;
  }

  @screen tablet {
    grid-template-columns: 1fr 1fr;

    .fern-btn--persimmon {
      grid-column: 1;
    }

    .fern-secondary-btn--persimmon {
      grid-column: 2;
      grid-row: 1;
    }
  }
}

.fern-modal--cancel {
  text-align: center;
  margin-top: 45px;
  display: block;
  border: none;
  background-color: none;
  appearance: none;
  cursor: pointer;
  width: 100%;
}
