.fern-image {
  width: 100%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
}

.fern-image--4x3 {
  padding-top: calc(300% / 4);
}

.fern-image--3x4 {
  padding-top: calc(400% / 3);
}

.fern-image--3x2 {
  padding-top: calc(200% / 3);
}

.fern-image--2x3 {
  padding-top: calc(300% / 2);
}

.fern-image--2x1 {
  padding-top: 50%;
}

.fern-image--1x1 {
  padding-top: 100%;
}
