.navbar-spacer {
  height: var(--navbar-mobile-height);

  @screen small-desktop {
    height: var(--navbar-desktop-height);
  }
}

.fern-navbar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  @apply bg-white;
  @apply border-b border-lightest-grey;
  z-index: 999;

  display: grid;
  grid-template-columns: 1fr 77px 1fr;
  align-items: center;

  /*  lines up with breadcrumbs */
  padding-left: 15px;
  padding-right: 15px;

  height: var(--navbar-mobile-height);
  @screen small-desktop {
    height: var(--navbar-desktop-height);
  }

  @screen small-desktop {
    grid-template-columns: 1fr 106px 1fr;
    padding-left: 60px;
    padding-right: 60px;
  }
}

.fern-navbar--left-column {
  @screen small-desktop {
    display: flex;
    height: 100%;
  }
}

.fern-navbar--right-column {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.fern-desktop-menu--item {
  display: none;

  @screen small-desktop {
    margin-right: 45px;
    display: flex;
    align-items: center;
    position: relative;

    /*  hover underline effect */
    &::after {
      content: '';
      @apply border-b-[3px] border-papaya;
      position: absolute;
      top: 0;
      bottom: 20px;
      left: 0;
      right: 100%;
      transition: right 0.3s;
      pointer-events: none;
    }

    &:hover::after {
      right: 0%;
    }
  }
}

.fern-desktop-menu--item-link {
  display: flex;
  align-items: center;
  height: 100%;
}

.flyout-animation-inactive {
  transition-property: transform, opacity, visibility;
  transition-duration: 0.2s;
  transform-origin: top;
  transform: scaleY(0);
  visibility: hidden;
  /* this transition delay daleays the flyout hiding, making it nicer for users when they move their mouse from the navbar item to the far right of the flyout.
   e.g. hover over "The Goods" then move your mouse to "Shop Curated Rooms", the flyout shouldn't flicker (as much) as you move your mouse over there. */
  transition-delay: 0.2s;
}

.fern-nav--flyout {
  position: fixed;
  top: var(--navbar-desktop-height);
  left: 0;
  right: 0;
  @apply bg-white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  opacity: 0;
  padding: 30px 0;

  &.active,
  .fern-desktop-menu--item:hover & {
    /* but we don't want the transition delay to occur when the user hovers over the navbar menu item */
    transition-delay: 0s;
    transform: scaleY(1);
    opacity: 1;
    visibility: visible;
  }
}

.fern-desktop-menu--icon-flyout-container {
  position: relative;
}

.fern-nav--small-flyout {
  position: fixed;
  top: var(--navbar-desktop-height);
  padding: 30px;
  @apply bg-white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  display: grid;
  grid-row-gap: 10px;
  margin-left: -55px;

  &.logged-in {
    margin-left: -67px;
  }

  .fern-desktop-menu--icon-flyout-container:hover &,
  .fern-desktop-menu--icon-flyout-container:focus & {
    /* but we don't want the transition delay to occur when the user hovers over the navbar menu item */
    transition-delay: 0s;
    transform: scaleY(1);
    opacity: 1;
    visibility: visible;
  }
}

.fern-nav--icon-link--mobile-only {
  display: flex;
  align-items: center;
  background: none;
  border: none;
  padding: 0;
  margin-left: 20px;

  margin-left: 0;
  width: 100%;
}

.fern-nav--icon-link {
  display: flex;
  align-items: center;
  background: none;
  border: none;
  padding: 0;
  margin-left: 20px;
}

.fern-nav--cart-link {
  position: relative;
  cursor: pointer;
  width: 30px;
  height: 30px;
  padding: 0;
}

.fern-nav--cart-item-count {
  height: 16px;
  width: 16px;
  border-radius: 100%;
  @apply bg-aqua;
  color: white;
  position: absolute;
  top: 0px;
  right: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}
