.fern-collapse {
  display: none;
}

.fern-collapse.in {
  display: block;
}

.fern-collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
}
