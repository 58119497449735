.text-field {
  position: relative;
  @apply transition-all duration-200;

  @apply border border-light-grey;

  input {
    outline: none;
    transition: border;
    @apply duration-200;
    @apply border border-transparent;
    padding-left: 15px;
    height: 100%;
    width: 100%;
  }
  textarea {
    width: 100%;
    @apply transition-all duration-200;
    @apply border border-transparent;
    outline: none;
    padding: 15px;
    vertical-align: top;
  }

  label {
    @apply transition-all duration-200;
    @apply text-grey;
    position: absolute;
    left: 15px;
    top: 11px;
  }

  /* ------------------------ */
  /* changing colors on hover */
  /* ------------------------ */
  @apply hover:border-aqua;

  /* ------------------------ */
  /* changing colors on focus */
  /* ------------------------ */
  @apply focus-within:border-aqua;

  input:focus,
  textarea:focus {
    @apply border border-aqua;
    border-radius: 0;
  }

  input:focus + label,
  textarea:focus + label {
    @apply text-aqua;
  }

  /* ------------------------ */
  /* moving label up and down */
  /* ------------------------ */
  &.entered > label,
  input:focus + label {
    @apply bg-white px-5 top-[-12px] left-10 text-[14px];
  }

  /* ------------------------ */
  /* error */
  /* ------------------------ */
  &.error {
    @apply border-pomegranate;

    input {
      @apply border-pomegranate;
      @apply pr-[40px];
    }

    textarea {
      @apply border-pomegranate;
    }

    input:focus + label {
      @apply text-pomegranate;
    }

    label {
      @apply text-pomegranate;
    }
  }
}

.no-number-arrows {
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }
}
