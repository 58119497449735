.backgroundContainer {
  display: grid;
  grid-template-columns: 4fr 7fr;
  height: 532px;
  background-color: theme('colors.sand');

  @screen desktop {
    grid-template-columns: 2fr 5fr;
    height: 720px;
  }
  @screen xl-desktop {
    grid-template-columns: 4fr 7fr;
  }
}

.heroBackground {
  background-color: theme('colors.sand');

  @screen small-desktop {
    height: 512px;
    display: flex;
    align-items: center;
  }

  @screen xl-desktop {
    height: 720px;
  }
}
