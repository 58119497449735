.collapse-caret {
  cursor: pointer;
  display: flex;
  transition: transform 0.3s;
  transform-origin: center;
  align-items: center;
  justify-content: center;

  &.expanded {
    transform: rotate(180deg);
  }

  &.small {
    font-size: 12px;
  }

  &.large {
    font-size: 16px;
  }
}
