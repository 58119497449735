.fern-paginated-carousel {
  margin-left: -15px;
  margin-right: -15px;
  position: relative;
  overflow-x: scroll;
  /* TODO */
  /* @include mouseDraggable;  */
  &::-webkit-scrollbar {
    display: none;
  }

  @screen small-desktop {
    overflow-x: hidden;
    width: calc(100% + 30px);
  }
}

.fern-paginated-carousel--wrapper {
  display: flex;
  transition: transform 300ms;

  &:after {
    content: '';
    border: 1px solid transparent;
    margin-left: -1px;
  }

  /* // default slide behavior: 4-across on desktop */
  &.four-across {
    .fern-paginated-carousel--slide {
      width: calc(218px + 30px);
      flex-shrink: 0;
      padding-left: 15px;
      padding-right: 15px;
      overflow: hidden;

      @screen small-desktop {
        width: 25%;
      }
    }
  }

  /* // 3-across on desktop */
  &.three-across {
    .fern-paginated-carousel--slide {
      width: calc(218px + 30px);
      flex-shrink: 0;
      padding-left: 15px;
      padding-right: 15px;
      overflow: hidden;

      @screen small-desktop {
        width: 33.33%;
      }
    }
  }

  /* // 6-across on desktop */
  &.six-across {
    .fern-paginated-carousel--slide {
      width: calc(72px + 30px);
      flex-shrink: 0;
      padding-left: 15px;
      padding-right: 15px;
      overflow: hidden;

      @screen small-desktop {
        width: 16.67%;
      }
    }
  }
}

.fern-paginated-carousel--nav-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: none;
  pointer-events: none;
  transition: height 300ms;
  z-index: 1;

  button {
    pointer-events: auto;
  }

  @screen small-desktop {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
