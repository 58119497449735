.circle {
  stroke-dasharray: 500;
  animation: dash 3s ease;
}

@keyframes dash {
  from {
    stroke-dashoffset: 500;
  }
  to {
    stroke-dashoffset: 0;
  }
}

.icon {
  transform-origin: center;
  animation: fadein 0.5s ease;
}

@keyframes fadein {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}

/* .icon {
  background-image: url('./success_check_persimmon.svg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 1.5625rem;
  height: 1.5625rem;
  margin-right: 0.625rem;
} */
