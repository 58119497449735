/* // mobile menu has its own z-index stack:
// 1: mobile menu overlay
// 2: mobile menu
// 3: mobile menu icon
// 4: cart overlay */

.fern-navbar--mobile-menu {
  width: 90vw;
  max-width: 450px;

  z-index: 2;
  position: fixed;
  top: 0;
  bottom: 0;
  left: -90vw;
  @apply bg-white;
  padding-top: 60px;
  padding-bottom: 30px;
  transition-property: left, visibility;
  transition-duration: 0.3s;
  overflow: scroll;
  visibility: hidden;

  &.opened {
    left: 0;
    visibility: visible;
  }
}

.fern-navbar--mobile-menu-overlay {
  z-index: 1;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  opacity: 0;
  bottom: 0;
  @apply bg-dark-brown;
  transition-property: opacity;
  transition-duration: 0.3s;
  pointer-events: none;

  &.opened {
    opacity: 0.6;
    pointer-events: initial;
  }
}

.fern-navbar--cart-overlay {
  @extend .fern-navbar--mobile-menu-overlay;
  z-index: 4;
}

.fern-mobile-menu--container {
  padding: 0 45px;
}

.fern-mobile-menu--item-label {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0;
}

.fern-mobile-menu--hr {
  @apply border-lightest-grey;
  margin-top: 0px;
  margin-bottom: 30px;

  &:last-of-type {
    margin-bottom: 60px;
  }
}

.fern-mobile-menu--sub-menu-content {
  display: none; /* initialize as display-none for animation purposes */
  padding-top: 30px;
}

.fern-mobile-menu--social-icons {
  display: flex;
  margin-bottom: 60px;
  font-size: 20px;

  a {
    margin-right: 20px;
  }

  i {
    transition: color 0.3s;
  }

  i:active {
    color: $papaya;
  }
}
