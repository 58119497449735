.fern-mobile-menu-icon {
  width: 18px;
  height: 18px;
  position: relative;
  cursor: pointer;
  z-index: 3;
  padding: 0;
  display: block;

  /* placing the lines */
  &:before,
  &:after {
    width: 100%;
    content: '';
    border-radius: 3px;
    display: block;
    height: 0px;
    @apply border border-solid border-dark-brown;
    position: absolute;
    transition-property: transform;
    transition-duration: 0.2s;
    transition-timing-function: ease;
    transition-delay: 0s;
    @apply bg-dark-brown;
  }

  &:before {
    top: 4px;
  }

  &:after {
    bottom: 4px;
  }

  /* animating the lines when cursor is active */
  &.active:before {
    transform: translate(0px, 4px) rotate(45deg);
  }

  &.active:after {
    transform: translate(0px, -4px) rotate(-45deg);
  }

  @screen small-desktop {
    display: none;
  }
}

#closeMobileMenu {
  position: fixed;
  left: 15px;
  top: 21px;
  z-index: 4;

  &:before,
  &:after {
    display: none;
  }
}
