.fern-nav--item-type-links-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 15px;
  grid-row-gap: 10px;
  grid-auto-flow: column;

  @screen small-desktop {
    @apply gap-x-30;
  }
}

.fern-nav--item-type-links-grid > div {
  grid-column: span 2;
}

.the-goods-desktop-flyout--item-type-column {
  grid-column: 1 / 13;
}

.the-goods-desktop-flyout--rooms-column {
  grid-column: 9 / 13;
}

.nav-dropdown--shop-all {
  margin-top: 30px;
  display: flex;
  align-items: center;
  line-height: 24px;
  @screen small-desktop {
    margin-top: 45px;
  }
}
.nav-dropdown--room {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 15px;
  margin: 15px 0;
  @screen small-desktop {
    margin: 15px 0 30px 0;
    display: flex;
    align-items: center;
    min-width: 0px;
  }
}
.nav-dropdown--room-name {
  display: flex;
  align-items: center;
}
.nav-dropdown--room-image {
  width: 100%;
  @screen small-desktop {
    width: 25%;
    margin-right: 45px;
  }
}
@mixin room-image {
  width: 100%;
  height: 0;
  padding-top: 75%;
  background-color: $oak;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  @screen small-desktop {
    box-sizing: content-box;
    padding-right: 30px;
    height: 22.5px;
  }
}
.nav-dropdown--room-image--bedroom {
  @include room-image;
  background-image: url('bedroom.jpg');
}
.nav-dropdown--room-image--dining-room {
  @include room-image;
  background-image: url('dining-room.jpg');
}
.nav-dropdown--room-image--living-room {
  @include room-image;
  background-image: url('living-room.jpg');
}
.nav-dropdown--header-link {
  @screen small-desktop {
    margin-top: 45px;
  }
  margin-top: 54px;
  &:first-child {
    margin-top: 0;
  }
}
.nav-dropdown--element {
  padding-top: 15px;
  display: block;
}
