.side-pagination-button {
  background-color: theme('colors.white');
  border: 1px solid theme('colors.sand');
  padding: 30px 8px;
  cursor: pointer;
  transition-property: color, background-color;
  transition-duration: 0.2s;

  @screen small-desktop {
    &:hover {
      background-color: theme('colors.papaya');
      color: theme('colors.white');
    }
  }

  &:active {
    color: theme('colors.white');
    background-color: theme('colors.papaya');
  }

  &[disabled],
  &.disabled {
    color: theme('colors.light-grey');
    background-color: theme('colors.white');

    &:active,
    &:hover {
      background-color: theme('colors.white');
    }
  }
}
