.hero_backgroundContainer__kq8x3 {
  display: grid;
  grid-template-columns: 4fr 7fr;
  height: 532px;
  background-color: #f2f0ed;
}

  @media (min-width: 1200px) {.hero_backgroundContainer__kq8x3 {
    grid-template-columns: 2fr 5fr;
    height: 720px
}
  }

  @media (min-width: 1600px) {.hero_backgroundContainer__kq8x3 {
    grid-template-columns: 4fr 7fr
}
  }

.hero_heroBackground__tkMij {
  background-color: #f2f0ed;
}

@media (min-width: 992px) {

.hero_heroBackground__tkMij {
    height: 512px;
    display: flex;
    align-items: center
}
  }

@media (min-width: 1600px) {

.hero_heroBackground__tkMij {
    height: 720px
}
  }

.fern_modal_fern-modal__RQYDb {
  background: white;
  z-index: 2000;
  padding: 15px 15px 30px 15px;
  margin: 0 15px;
  width: 100%;
  max-width: 610px;
  position: relative;
}
  @media (min-width: 576px) {.fern_modal_fern-modal__RQYDb {
    padding: 45px 60px 30px 60px
}
  }
  .fern_modal_fern-modal__RQYDb.fern_modal_fullscreen__kyssJ {
    height: 100%;
    width: 100vw;
    max-width: 100vw;
    margin: 0;
    overflow-y: scroll;
  }
  @media (min-width: 992px) {
  .fern_modal_fern-modal__RQYDb.fern_modal_fullscreen__kyssJ {
      height: auto;
      width: 100%;
      max-width: 610px;
      max-height: 95vh;
      margin: 0 15px
  }
    }

.fern_modal_fullscreen-close-container__Gs_v9 {
  position: sticky;
  top: 0;
  text-align: right;
  margin-bottom: 15px;
}

.fern_modal_fullscreen-close-container__Gs_v9 button {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
    border-radius: 100%;
    height: 32px;
    width: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 0;
}

@media (min-width: 992px) {

.fern_modal_fullscreen-close-container__Gs_v9 {
    margin-top: -30px;
    top: -30px;
    margin-right: -45px;
    margin-left: -45px;
    margin-bottom: 5px
}

    .fern_modal_fullscreen-close-container__Gs_v9 button {
      height: auto;
      width: auto;
    }
  }

.fern_modal_fern-modal--close__jFUTq {
  position: absolute;
  top: 15px;
  right: 15px;
  border: none;
}

.fern_modal_fern-modal--button-container__oswum {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}

.fern_modal_fern-modal--buttons__Nra_Q {
  display: grid;
  grid-column-gap: 15px;
  grid-row-gap: 15px;
  grid-template-columns: 1fr;
}

.fern_modal_fern-modal--buttons__Nra_Q button {
    min-width: 140px;
  }

.fern_modal_fern-modal--buttons__Nra_Q.fern_modal_one-button__51brL {
    display: block;
  }

@media (min-width: 576px) {

.fern_modal_fern-modal--buttons__Nra_Q {
    grid-template-columns: 1fr 1fr
}

    .fern_modal_fern-modal--buttons__Nra_Q .fern_modal_fern-btn--persimmon__rAWlQ {
      grid-column: 1;
    }

    .fern_modal_fern-modal--buttons__Nra_Q .fern_modal_fern-secondary-btn--persimmon__FfFF5 {
      grid-column: 2;
      grid-row: 1;
    }
  }

.fern_modal_fern-modal--cancel__xQu8E {
  text-align: center;
  margin-top: 45px;
  display: block;
  border: none;
  background-color: none;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  cursor: pointer;
  width: 100%;
}

.side_pagination_button_side-pagination-button__mNgC6 {
  background-color: #ffffff;
  border: 1px solid #f2f0ed;
  padding: 30px 8px;
  cursor: pointer;
  transition-property: color, background-color;
  transition-duration: 0.2s;
}

  @media (min-width: 992px) {
    .side_pagination_button_side-pagination-button__mNgC6:hover {
      background-color: #f68e65;
      color: #ffffff;
    }
  }

  .side_pagination_button_side-pagination-button__mNgC6:active {
    color: #ffffff;
    background-color: #f68e65;
  }

  .side_pagination_button_side-pagination-button__mNgC6[disabled],
  .side_pagination_button_side-pagination-button__mNgC6.side_pagination_button_disabled__sKeWW {
    color: #dcd8d5;
    background-color: #ffffff;
  }

  .side_pagination_button_side-pagination-button__mNgC6[disabled]:active,
    .side_pagination_button_side-pagination-button__mNgC6[disabled]:hover,
    .side_pagination_button_side-pagination-button__mNgC6.side_pagination_button_disabled__sKeWW:active,
    .side_pagination_button_side-pagination-button__mNgC6.side_pagination_button_disabled__sKeWW:hover {
      background-color: #ffffff;
    }

.paginated_carousel_fern-paginated-carousel__AgTmb {
  margin-left: -15px;
  margin-right: -15px;
  position: relative;
  overflow-x: scroll;
}
  /* TODO */
  /* @include mouseDraggable;  */
  .paginated_carousel_fern-paginated-carousel__AgTmb::-webkit-scrollbar {
    display: none;
  }
  @media (min-width: 992px) {.paginated_carousel_fern-paginated-carousel__AgTmb {
    overflow-x: hidden;
    width: calc(100% + 30px)
}
  }

.paginated_carousel_fern-paginated-carousel--wrapper__NngR2 {
  display: flex;
  transition: transform 300ms;
}

.paginated_carousel_fern-paginated-carousel--wrapper__NngR2:after {
    content: '';
    border: 1px solid transparent;
    margin-left: -1px;
  }

/* // default slide behavior: 4-across on desktop */

.paginated_carousel_fern-paginated-carousel--wrapper__NngR2.paginated_carousel_four-across__lIiQ4 .paginated_carousel_fern-paginated-carousel--slide__LvOA0 {
      width: calc(218px + 30px);
      flex-shrink: 0;
      padding-left: 15px;
      padding-right: 15px;
      overflow: hidden;
    }

@media (min-width: 992px) {

.paginated_carousel_fern-paginated-carousel--wrapper__NngR2.paginated_carousel_four-across__lIiQ4 .paginated_carousel_fern-paginated-carousel--slide__LvOA0 {
        width: 25%
    }
      }

/* // 3-across on desktop */

.paginated_carousel_fern-paginated-carousel--wrapper__NngR2.paginated_carousel_three-across__eNLNJ .paginated_carousel_fern-paginated-carousel--slide__LvOA0 {
      width: calc(218px + 30px);
      flex-shrink: 0;
      padding-left: 15px;
      padding-right: 15px;
      overflow: hidden;
    }

@media (min-width: 992px) {

.paginated_carousel_fern-paginated-carousel--wrapper__NngR2.paginated_carousel_three-across__eNLNJ .paginated_carousel_fern-paginated-carousel--slide__LvOA0 {
        width: 33.33%
    }
      }

/* // 6-across on desktop */

.paginated_carousel_fern-paginated-carousel--wrapper__NngR2.paginated_carousel_six-across__20Uop .paginated_carousel_fern-paginated-carousel--slide__LvOA0 {
      width: calc(72px + 30px);
      flex-shrink: 0;
      padding-left: 15px;
      padding-right: 15px;
      overflow: hidden;
    }

@media (min-width: 992px) {

.paginated_carousel_fern-paginated-carousel--wrapper__NngR2.paginated_carousel_six-across__20Uop .paginated_carousel_fern-paginated-carousel--slide__LvOA0 {
        width: 16.67%
    }
      }

.paginated_carousel_fern-paginated-carousel--nav-container__2sk7q {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: none;
  pointer-events: none;
  transition: height 300ms;
  z-index: 1;
}

.paginated_carousel_fern-paginated-carousel--nav-container__2sk7q button {
    pointer-events: auto;
  }

@media (min-width: 992px) {

.paginated_carousel_fern-paginated-carousel--nav-container__2sk7q {
    display: flex;
    justify-content: space-between;
    align-items: center
}
  }

.fern-image_fern-image__NPHNu {
  width: 100%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
}

.fern-image_fern-image--4x3__bbQMf {
  padding-top: calc(300% / 4);
}

.fern-image_fern-image--3x4__oM1h7 {
  padding-top: calc(400% / 3);
}

.fern-image_fern-image--3x2__KUcNQ {
  padding-top: calc(200% / 3);
}

.fern-image_fern-image--2x3__Jl3Zx {
  padding-top: calc(300% / 2);
}

.fern-image_fern-image--2x1__Hbd7v {
  padding-top: 50%;
}

.fern-image_fern-image--1x1__qyoi0 {
  padding-top: 100%;
}

.text-field_text-field__PUmTF {
  position: relative;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 200ms;
  border-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgb(220 216 213 / var(--tw-border-opacity));
}

  .text-field_text-field__PUmTF input {
    outline: none;
    transition: border;
    transition-duration: 200ms;
    border-width: 1px;
    border-color: transparent;
    padding-left: 15px;
    height: 100%;
    width: 100%;
  }

  .text-field_text-field__PUmTF textarea {
    width: 100%;
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 200ms;
    border-width: 1px;
    border-color: transparent;
    outline: none;
    padding: 15px;
    vertical-align: top;
  }

  .text-field_text-field__PUmTF label {
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 200ms;
  --tw-text-opacity: 1;
  color: rgb(111 105 101 / var(--tw-text-opacity));
    position: absolute;
    left: 15px;
    top: 11px;
}

  .text-field_text-field__PUmTF:hover {
  --tw-border-opacity: 1;
  border-color: rgb(0 123 147 / var(--tw-border-opacity));
}

  .text-field_text-field__PUmTF:focus-within {
  --tw-border-opacity: 1;
  border-color: rgb(0 123 147 / var(--tw-border-opacity));
}

  /* ------------------------ */

  /* changing colors on hover */

  /* ------------------------ */

  /* ------------------------ */

  /* changing colors on focus */

  /* ------------------------ */

  .text-field_text-field__PUmTF input:focus,
  .text-field_text-field__PUmTF textarea:focus {
  border-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgb(0 123 147 / var(--tw-border-opacity));
    border-radius: 0;
}

  .text-field_text-field__PUmTF input:focus + label,
  .text-field_text-field__PUmTF textarea:focus + label {
  --tw-text-opacity: 1;
  color: rgb(0 123 147 / var(--tw-text-opacity));
}

  /* ------------------------ */

  /* moving label up and down */

  /* ------------------------ */

  .text-field_text-field__PUmTF.text-field_entered__KDhw2 > label,
  .text-field_text-field__PUmTF input:focus + label {
  top: -12px;
  left: 10px;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  padding-left: 5px;
  padding-right: 5px;
  font-size: 14px;
}

  /* ------------------------ */

  /* error */

  /* ------------------------ */

  .text-field_text-field__PUmTF.text-field_error__wRtHw {
  --tw-border-opacity: 1;
  border-color: rgb(207 74 21 / var(--tw-border-opacity));
}

  .text-field_text-field__PUmTF.text-field_error__wRtHw input {
  --tw-border-opacity: 1;
  border-color: rgb(207 74 21 / var(--tw-border-opacity));
  padding-right: 40px;
}

  .text-field_text-field__PUmTF.text-field_error__wRtHw textarea {
  --tw-border-opacity: 1;
  border-color: rgb(207 74 21 / var(--tw-border-opacity));
}

  .text-field_text-field__PUmTF.text-field_error__wRtHw input:focus + label {
  --tw-text-opacity: 1;
  color: rgb(207 74 21 / var(--tw-text-opacity));
}

  .text-field_text-field__PUmTF.text-field_error__wRtHw label {
  --tw-text-opacity: 1;
  color: rgb(207 74 21 / var(--tw-text-opacity));
}

/* Chrome, Safari, Edge, Opera */

.text-field_no-number-arrows__niPP2 input::-webkit-outer-spin-button,
  .text-field_no-number-arrows__niPP2 input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

/* Firefox */

.text-field_no-number-arrows__niPP2 input[type='number'] {
    -moz-appearance: textfield;
  }

.hero_widget_backgroundContainer__TqXT7 {
  display: grid;
  grid-template-columns: 4fr 7fr;
  height: 532px;
}

  @media (min-width: 1200px) {.hero_widget_backgroundContainer__TqXT7 {
    grid-template-columns: 2fr 5fr;
    height: 720px
}
  }

  @media (min-width: 1600px) {.hero_widget_backgroundContainer__TqXT7 {
    grid-template-columns: 4fr 7fr
}
  }

@media (min-width: 992px) {

.hero_widget_heroBackground___DZBW {
    height: 512px;
    display: flex;
    align-items: center
}
  }

@media (min-width: 1600px) {

.hero_widget_heroBackground___DZBW {
    height: 720px
}
  }

.animated_success_icon_circle__eNlKO {
  stroke-dasharray: 500;
  animation: animated_success_icon_dash__StylD 3s ease;
}

@keyframes animated_success_icon_dash__StylD {
  from {
    stroke-dashoffset: 500;
  }
  to {
    stroke-dashoffset: 0;
  }
}

.animated_success_icon_icon__2P4iq {
  transform-origin: center;
  animation: animated_success_icon_fadein__fzIO_ 0.5s ease;
}

@keyframes animated_success_icon_fadein__fzIO_ {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}

/* .icon {
  background-image: url('./success_check_persimmon.svg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 1.5625rem;
  height: 1.5625rem;
  margin-right: 0.625rem;
} */

.nav-submenu-the-goods_fern-nav--item-type-links-grid__rMyLS {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 15px;
  grid-row-gap: 10px;
  grid-auto-flow: column;
}

  @media (min-width: 992px) {.nav-submenu-the-goods_fern-nav--item-type-links-grid__rMyLS {
    -moz-column-gap: 30px;
         column-gap: 30px;
  }
  }

.nav-submenu-the-goods_fern-nav--item-type-links-grid__rMyLS > div {
  grid-column: span 2;
}

.nav-submenu-the-goods_the-goods-desktop-flyout--item-type-column__HF2wV {
  grid-column: 1 / 13;
}

.nav-submenu-the-goods_the-goods-desktop-flyout--rooms-column__zBLKp {
  grid-column: 9 / 13;
}

.nav-submenu-the-goods_nav-dropdown--shop-all__yJriS {
  margin-top: 30px;
  display: flex;
  align-items: center;
  line-height: 24px;
}

@media (min-width: 992px) {

.nav-submenu-the-goods_nav-dropdown--shop-all__yJriS {
    margin-top: 45px
}
  }
.nav-submenu-the-goods_nav-dropdown--room__goAVw {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 15px;
  margin: 15px 0;
}
@media (min-width: 992px) {
.nav-submenu-the-goods_nav-dropdown--room__goAVw {
    margin: 15px 0 30px 0;
    display: flex;
    align-items: center;
    min-width: 0px
}
  }
.nav-submenu-the-goods_nav-dropdown--room-name__RC07Q {
  display: flex;
  align-items: center;
}
.nav-submenu-the-goods_nav-dropdown--room-image__RLWHK {
  width: 100%;
}
@media (min-width: 992px) {
.nav-submenu-the-goods_nav-dropdown--room-image__RLWHK {
    width: 25%;
    margin-right: 45px
}
  }
@mixin room-image {
  width: 100%;
  height: 0;
  padding-top: 75%;
  background-color: $oak;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  @media (min-width: 992px) {
    box-sizing: content-box;
    padding-right: 30px;
    height: 22.5px;
  }
}
.nav-submenu-the-goods_nav-dropdown--room-image--bedroom__MFqTN {
  @include room-image;
  background-image: url(/_next/static/media/bedroom.aacd826d.jpg);
}
.nav-submenu-the-goods_nav-dropdown--room-image--dining-room__qJXal {
  @include room-image;
  background-image: url(/_next/static/media/dining-room.fae9f44a.jpg);
}
.nav-submenu-the-goods_nav-dropdown--room-image--living-room__Ip972 {
  @include room-image;
  background-image: url(/_next/static/media/living-room.413d364b.jpg);
}
@media (min-width: 992px) {
.nav-submenu-the-goods_nav-dropdown--header-link__u7OuU {
    margin-top: 45px
}
  }
.nav-submenu-the-goods_nav-dropdown--header-link__u7OuU {
  margin-top: 54px;
}
.nav-submenu-the-goods_nav-dropdown--header-link__u7OuU:first-child {
    margin-top: 0;
  }
.nav-submenu-the-goods_nav-dropdown--element__T8mrs {
  padding-top: 15px;
  display: block;
}

.collapse_fern-collapse__rzF5L {
  display: none;
}

.collapse_fern-collapse__rzF5L.collapse_in___ZCbD {
  display: block;
}

.collapse_fern-collapsing__ToBnX {
  position: relative;
  height: 0;
  overflow: hidden;
}

.collapse_caret_collapse-caret__osjfc {
  cursor: pointer;
  display: flex;
  transition: transform 0.3s;
  transform-origin: center;
  align-items: center;
  justify-content: center;
}

  .collapse_caret_collapse-caret__osjfc.collapse_caret_expanded__KaR2L {
    transform: rotate(180deg);
  }

  .collapse_caret_collapse-caret__osjfc.collapse_caret_small__fa7sP {
    font-size: 12px;
  }

  .collapse_caret_collapse-caret__osjfc.collapse_caret_large__smrMv {
    font-size: 16px;
  }

/* // mobile menu has its own z-index stack:
// 1: mobile menu overlay
// 2: mobile menu
// 3: mobile menu icon
// 4: cart overlay */

.nav-mobile-menu_fern-navbar--mobile-menu__A84sc {
  width: 90vw;
  max-width: 450px;

  z-index: 2;
  position: fixed;
  top: 0;
  bottom: 0;
  left: -90vw;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  padding-top: 60px;
  padding-bottom: 30px;
  transition-property: left, visibility;
  transition-duration: 0.3s;
  overflow: scroll;
  visibility: hidden;
}

.nav-mobile-menu_fern-navbar--mobile-menu__A84sc.nav-mobile-menu_opened__z1rwN {
    left: 0;
    visibility: visible;
  }

.nav-mobile-menu_fern-navbar--mobile-menu-overlay__HnBIK {
  z-index: 1;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  opacity: 0;
  bottom: 0;
  --tw-bg-opacity: 1;
  background-color: rgb(57 48 42 / var(--tw-bg-opacity));
  transition-property: opacity;
  transition-duration: 0.3s;
  pointer-events: none;
}

.nav-mobile-menu_fern-navbar--mobile-menu-overlay__HnBIK.nav-mobile-menu_opened__z1rwN {
    opacity: 0.6;
    pointer-events: initial;
  }

.nav-mobile-menu_fern-navbar--cart-overlay__lLMNY {
  @extend .fern-navbar--mobile-menu-overlay;
  z-index: 4;
}

.nav-mobile-menu_fern-mobile-menu--container__bAwy1 {
  padding: 0 45px;
}

.nav-mobile-menu_fern-mobile-menu--item-label__P3pb4 {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0;
}

.nav-mobile-menu_fern-mobile-menu--hr__fSvGQ {
  --tw-border-opacity: 1;
  border-color: rgb(245 245 245 / var(--tw-border-opacity));
  margin-top: 0px;
  margin-bottom: 30px;
}

.nav-mobile-menu_fern-mobile-menu--hr__fSvGQ:last-of-type {
    margin-bottom: 60px;
  }

.nav-mobile-menu_fern-mobile-menu--sub-menu-content__PC1Ao {
  display: none; /* initialize as display-none for animation purposes */
  padding-top: 30px;
}

.nav-mobile-menu_fern-mobile-menu--social-icons__wLZJY {
  display: flex;
  margin-bottom: 60px;
  font-size: 20px;
}

.nav-mobile-menu_fern-mobile-menu--social-icons__wLZJY a {
    margin-right: 20px;
  }

.nav-mobile-menu_fern-mobile-menu--social-icons__wLZJY i {
    transition: color 0.3s;
  }

.nav-mobile-menu_fern-mobile-menu--social-icons__wLZJY i:active {
    color: $papaya;
  }

.nav-mobile-menu-icon_fern-mobile-menu-icon__iEuJn {
  width: 18px;
  height: 18px;
  position: relative;
  cursor: pointer;
  z-index: 3;
  padding: 0;
  display: block;
}

  /* placing the lines */
  .nav-mobile-menu-icon_fern-mobile-menu-icon__iEuJn:before,
  .nav-mobile-menu-icon_fern-mobile-menu-icon__iEuJn:after {
    width: 100%;
    content: '';
    border-radius: 3px;
    display: block;
    height: 0px;
    border-width: 1px;
    border-style: solid;
    --tw-border-opacity: 1;
    border-color: rgb(57 48 42 / var(--tw-border-opacity));
    position: absolute;
    transition-property: transform;
    transition-duration: 0.2s;
    transition-timing-function: ease;
    transition-delay: 0s;
    --tw-bg-opacity: 1;
    background-color: rgb(57 48 42 / var(--tw-bg-opacity));
  }
  .nav-mobile-menu-icon_fern-mobile-menu-icon__iEuJn:before {
    top: 4px;
  }
  .nav-mobile-menu-icon_fern-mobile-menu-icon__iEuJn:after {
    bottom: 4px;
  }
  /* animating the lines when cursor is active */
  .nav-mobile-menu-icon_fern-mobile-menu-icon__iEuJn.nav-mobile-menu-icon_active__ydX2L:before {
    transform: translate(0px, 4px) rotate(45deg);
  }
  .nav-mobile-menu-icon_fern-mobile-menu-icon__iEuJn.nav-mobile-menu-icon_active__ydX2L:after {
    transform: translate(0px, -4px) rotate(-45deg);
  }
  @media (min-width: 992px) {.nav-mobile-menu-icon_fern-mobile-menu-icon__iEuJn {
    display: none
}
  }

#nav-mobile-menu-icon_closeMobileMenu__ThBIX {
  position: fixed;
  left: 15px;
  top: 21px;
  z-index: 4;
}

#nav-mobile-menu-icon_closeMobileMenu__ThBIX:before,
  #nav-mobile-menu-icon_closeMobileMenu__ThBIX:after {
    display: none;
  }

.navbar_navbar-spacer__DZsiP {
  height: var(--navbar-mobile-height);
}

  @media (min-width: 992px) {.navbar_navbar-spacer__DZsiP {
    height: var(--navbar-desktop-height)
}
  }

.navbar_fern-navbar__61gmY {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  border-bottom-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgb(245 245 245 / var(--tw-border-opacity));
  z-index: 999;

  display: grid;
  grid-template-columns: 1fr 77px 1fr;
  align-items: center;

  /*  lines up with breadcrumbs */
  padding-left: 15px;
  padding-right: 15px;

  height: var(--navbar-mobile-height);
}

@media (min-width: 992px) {

.navbar_fern-navbar__61gmY {
    height: var(--navbar-desktop-height);
    grid-template-columns: 1fr 106px 1fr;
    padding-left: 60px;
    padding-right: 60px
}

.navbar_fern-navbar--left-column__c20uk {
    display: flex;
    height: 100%
}
  }

.navbar_fern-navbar--right-column__6e0Ir {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.navbar_fern-desktop-menu--item__88xpm {
  display: none;
}

@media (min-width: 992px) {

.navbar_fern-desktop-menu--item__88xpm {
    margin-right: 45px;
    display: flex;
    align-items: center;
    position: relative

    /*  hover underline effect */
}
    .navbar_fern-desktop-menu--item__88xpm::after {
      content: '';
      border-bottom-width: 3px;
      --tw-border-opacity: 1;
      border-color: rgb(246 142 101 / var(--tw-border-opacity));
      position: absolute;
      top: 0;
      bottom: 20px;
      left: 0;
      right: 100%;
      transition: right 0.3s;
      pointer-events: none;
    }

    .navbar_fern-desktop-menu--item__88xpm:hover::after {
      right: 0%;
    }
  }

.navbar_fern-desktop-menu--item-link__rGu7n {
  display: flex;
  align-items: center;
  height: 100%;
}

.navbar_flyout-animation-inactive__Jg8ky {
  transition-property: transform, opacity, visibility;
  transition-duration: 0.2s;
  transform-origin: top;
  transform: scaleY(0);
  visibility: hidden;
  /* this transition delay daleays the flyout hiding, making it nicer for users when they move their mouse from the navbar item to the far right of the flyout.
   e.g. hover over "The Goods" then move your mouse to "Shop Curated Rooms", the flyout shouldn't flicker (as much) as you move your mouse over there. */
  transition-delay: 0.2s;
}

.navbar_fern-nav--flyout__gUgUz {
  position: fixed;
  top: var(--navbar-desktop-height);
  left: 0;
  right: 0;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  opacity: 0;
  padding: 30px 0;
}

.navbar_fern-nav--flyout__gUgUz.navbar_active__9ESv3,
  .navbar_fern-desktop-menu--item__88xpm:hover .navbar_fern-nav--flyout__gUgUz {
    /* but we don't want the transition delay to occur when the user hovers over the navbar menu item */
    transition-delay: 0s;
    transform: scaleY(1);
    opacity: 1;
    visibility: visible;
  }

.navbar_fern-desktop-menu--icon-flyout-container__CxVDN {
  position: relative;
}

.navbar_fern-nav--small-flyout__dxvKV {
  position: fixed;
  top: var(--navbar-desktop-height);
  padding: 30px;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  display: grid;
  grid-row-gap: 10px;
  margin-left: -55px;
}

.navbar_fern-nav--small-flyout__dxvKV.navbar_logged-in__68WKp {
    margin-left: -67px;
  }

.navbar_fern-desktop-menu--icon-flyout-container__CxVDN:hover .navbar_fern-nav--small-flyout__dxvKV,
  .navbar_fern-desktop-menu--icon-flyout-container__CxVDN:focus .navbar_fern-nav--small-flyout__dxvKV {
    /* but we don't want the transition delay to occur when the user hovers over the navbar menu item */
    transition-delay: 0s;
    transform: scaleY(1);
    opacity: 1;
    visibility: visible;
  }

.navbar_fern-nav--icon-link--mobile-only__DiG4f {
  display: flex;
  align-items: center;
  background: none;
  border: none;
  padding: 0;
  margin-left: 20px;

  margin-left: 0;
  width: 100%;
}

.navbar_fern-nav--icon-link__03zZb {
  display: flex;
  align-items: center;
  background: none;
  border: none;
  padding: 0;
  margin-left: 20px;
}

.navbar_fern-nav--cart-link__NNHpW {
  position: relative;
  cursor: pointer;
  width: 30px;
  height: 30px;
  padding: 0;
}

.navbar_fern-nav--cart-item-count__E8E3t {
  height: 16px;
  width: 16px;
  border-radius: 100%;
  --tw-bg-opacity: 1;
  background-color: rgb(0 123 147 / var(--tw-bg-opacity));
  color: white;
  position: absolute;
  top: 0px;
  right: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}

